import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { filterSchedules } from '../services/api/apiSchedule.js';
import { appendAppointments } from './appointmentsSlice.js';
import { appendPatients } from './patientsSlice.js';

export const ScheduleStatuses = {
  AVAILABLE: 'AVAILABLE',
  BOOKING_HELD: 'BOOKING_HELD',
  BOOKED: 'BOOKED',
};

export const schedulesSlice = createSlice({
  name: 'schedules',
  initialState: {
    entities: [],
    lastUpdated: null,
  },
  // Redux Toolkit allows us to write "mutating" logic in reducers. It
  // doesn't actually mutate the state because it uses the Immer library,
  // which detects changes to a "draft state" and produces a brand new
  // immutable state based off those changes
  reducers: {
    updateLastUpdatedDate: (state) => {
      state.lastUpdated = new Date().toLocaleDateString('en-GB', {
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
    },
    replaceSchedules: (state, action) => {
      state.entities = action.payload;
    },
    addSchedule: (state, action) => {
      state.entities.push(action.payload);
    },
    replaceSchedule: (state, action) => {
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      if (index !== -1) {
        state.entities[index] = action.payload;
      } else {
        state.entities.push(action.payload);
      }
    },
    appendSchedules: (state, action) => {
      const payloadIds = [
        ...Object.values(action.payload.map((item) => item._id)),
      ];
      state.entities = state.entities
        .filter((el) => !payloadIds.includes(el._id))
        .concat(action.payload);
    },
    removeSchedule: (state, action) => {
      state.entities = state.entities.filter((el) => el._id !== action.payload);
    },
  },
});

export const {
  replaceSchedules,
  addSchedule,
  appendSchedules,
  replaceSchedule,
  removeSchedule,
  updateLastUpdatedDate,
} = schedulesSlice.actions;

const sortSchedulesByPractitionerStartAt = (schedules) => {
  return [...schedules].sort((a, b) =>
    dayjs(a.practitionerStartAt).isBefore(b.practitionerStartAt) ? -1 : 1
  );
};

// Selectors ******************************************************************
// The functions below are called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export const selectAllSchedules = (state) => {
  return sortSchedulesByPractitionerStartAt(state.schedules.entities);
};

export const getSchedulesUpdatedDate = (state) => {
  return state.schedules.lastUpdated;
};

export const getMonthsSchedules = async (
  dateFrom,
  dispatch,
  setApiError = null
) => {
  if (setApiError) setApiError(null);
  try {
    const monthSchedules = await filterSchedules(dateFrom);
    if (monthSchedules.schedules)
      dispatch(appendSchedules(monthSchedules.schedules));
    if (monthSchedules.appointments)
      dispatch(appendAppointments(monthSchedules.appointments));
    if (monthSchedules.patients)
      dispatch(appendPatients(monthSchedules.patients));
    dispatch(updateLastUpdatedDate());
  } catch (err) {
    console.log(err);
    if (setApiError)
      setApiError('There was an error getting schedules. Please try again.');
  }
};

export default schedulesSlice.reducer;
