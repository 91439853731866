/* eslint-disable react/jsx-no-comment-textnodes */
import './Week.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek.js';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import Button from '../elements/Button.js';
import TextTitle from '../elements/TextTitle.js';
import TextBody from '../elements/TextBody.js';
import AlertInfo from '../elements/alert/Info.js';
import AlertConfirm from '../elements/alert/Confirm.js';
import {
  sendReminder as remind,
  move,
} from '../../services/api/apiAppointment.js';
import {
  selectAllSchedules,
  ScheduleStatuses,
  removeSchedule,
  getSchedulesUpdatedDate,
} from '../../slices/schedulesSlice.js';
import {
  selectAllAppointments,
  AppointmentStatuses,
} from '../../slices/appointmentsSlice.js';
import { selectAllPatients } from '../../slices/patientsSlice.js';
import { selectAllPractitioners } from '../../slices/practitionersSlice.js';
import { remove, hold } from '../../services/api/apiSchedule.js';

import { fetchAdmin } from '../../slices/adminSlice.js';

dayjs.extend(advancedFormat);
dayjs.extend(isoWeek);

const Week = ({
  weekStart,
  prac = null,
  status = null,
  sourceMoveAppt = null,
  setSourceMoveAppt = null,
}) => {
  const schedules = useSelector(selectAllSchedules);
  const dispatch = useDispatch();
  const appointments = useSelector(selectAllAppointments);
  const patients = useSelector(selectAllPatients);
  const practitioners = useSelector(selectAllPractitioners);
  const [apiError, setApiError] = useState();
  const lastUpdated = useSelector(getSchedulesUpdatedDate);
  const [showScheduleRemoveConfirm, setShowScheduleRemoveConfirm] =
    useState(false);
  const [scheduleIdToRemove, setScheduleIdToRemove] = useState(null);

  const [isShowRemindDone, setShowAlertConfirm] = useState(false);

  const sendReminder = async (id) => {
    await remind(id);
  };

  const schedulesInWeek = schedules.filter((el) => {
    return dayjs(el.practitionerStartAt).isSame(dayjs(weekStart), 'isoweek');
  });

  const removeScheduleRequest = async (scheduleId) => {
    try {
      await remove(scheduleId);
      dispatch(removeSchedule(scheduleId));
    } catch (err) {
      setApiError(
        'There was an error removing the schedule. Please try again.'
      );
    }
  };

  const setHold = async (schedule, state) => {
    await hold(schedule._id, state);
    console.log('Setting hold');
    dispatch(fetchAdmin());
  };

  const onClickRemoveAppointment = (schedule) => {
    setScheduleIdToRemove(schedule._id);
    setShowScheduleRemoveConfirm(true);
  };

  const onScheduleRemoveConfirmNo = () => {
    setShowScheduleRemoveConfirm(false);
    setScheduleIdToRemove(null);
  };

  const onScheduleRemoveConfirmYes = async () => {
    setShowScheduleRemoveConfirm(false);
    removeScheduleRequest(scheduleIdToRemove);
    setScheduleIdToRemove(null);
  };

  const renderTableRow = (schedule, rowClass) => {
    const practitioner = practitioners.find(
      (el) => el._id.toString() === schedule.practitionerId.toString()
    );

    const practitionerName = practitioner
      ? [
          practitioner.title,
          practitioner.firstName,
          practitioner.lastName,
        ].join(' ')
      : ' (unknown) ';

    const appointment = appointments.find((el) => {
      // There can be multiple appointments for a schedule due to an appointment being
      // cancelled and booked by someone else, or by a user selecting a schedule slot
      // and not finishing booking (appointment will be in BOOKING_HELD)
      const matchScheduleId =
        el.scheduleId.toString() === schedule._id.toString();

      const matchStatus = ![
        AppointmentStatuses.BOOKING_HELD,
        AppointmentStatuses.CANCELLED,
      ].includes(el.status);

      return matchScheduleId && matchStatus;
    });

    const patientDetails = [];
    let price = '-';

    let appointmentStatus = schedule.status;
    if (schedule.status === ScheduleStatuses.BOOKING_HELD) {
      const bookingHeldUntil = dayjs(schedule.bookingHeldUntil);
      appointmentStatus += ` (Held until: ${bookingHeldUntil.format(
        'HH:mm DD/MM/YYYY'
      )})`;
    } else if (
      schedule.status === ScheduleStatuses.AVAILABLE &&
      dayjs(schedule.practitionerStartAt).isBefore(dayjs())
    ) {
      appointmentStatus = 'NOT_BOOKED_PEND';
    } else if (appointment) {
      appointmentStatus = appointment.status;

      const patient = patients.find(
        (el) => el._id.toString() === appointment.patientId.toString()
      );

      appointmentStatus = appointment.status;

      const patientName = patient
        ? [patient.firstName, patient.middleName, patient.lastName].join(' ')
        : 'Undefined Name';

      if (
        !patient ||
        patient.firstName === undefined ||
        patient.lastName === undefined
      ) {
        console.log(
          'patient name is undefined for appointment id',
          appointment._id
        );
      }

      if (patient)
        patientDetails.push(
          `Name: ${patientName} (PID:: HH-${patient.number})`
        );
      if (patient) patientDetails.push(`Email: ${patient.email}`);
      if (patient) patientDetails.push(`Tel: ${patient.phoneNumber}`);

      const { stripeSession } = appointment;
      if (stripeSession && stripeSession.payment_status === 'paid') {
        price = stripeSession.amount_total / 100;
      }
    }

    const doMove = async (targetScheduleId) => {
      await move(sourceMoveAppt._id, targetScheduleId._id);
      dispatch(fetchAdmin());
    };
    return (
      <tr key={schedule._id} className={rowClass}>
        <td>{dayjs(schedule.patientStartAt).format('ddd Do MMM')}</td>
        <td>{dayjs(schedule.patientStartAt).format('hh:mmA')}</td>
        <td>{dayjs(schedule.patientEndAt).format('hh:mmA')}</td>
        <td>{practitionerName}</td>
        <td>{appointmentStatus}</td>
        <td>{price}</td>
        <td>
          {patientDetails.map((el, i) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={i}>
                {el}
                <br />
              </div>
            );
          })}
        </td>
        <td className="home-week__table-view-appointment">
          {appointment ? (
            <div>
              {!sourceMoveAppt && (
                <Link
                  to={`/ops/O5CeoNpNYNIGC9kcCRbVnRLNDWMWunVW/appointments/${appointment._id}`}
                >
                  View Appointment
                </Link>
              )}
              {isShowRemindDone && (
                <AlertInfo
                  title="Reminder"
                  label={'Reminder has been sent to patient'}
                  onClose={() => setShowAlertConfirm(false)}
                />
              )}
              {!sourceMoveAppt &&
                appointment.status === 'BOOKED' &&
                dayjs(schedule.patientEndAt).unix() > dayjs().unix() && (
                  <Button
                    onClick={() => {
                      sendReminder(appointment._id);
                      setShowAlertConfirm(true);
                    }}
                  >
                    Remind
                  </Button>
                )}
            </div>
          ) : null}
          {!sourceMoveAppt &&
            !appointment &&
            ['AVAILABLE'].includes(schedule.status) &&
            dayjs(schedule.patientStartAt).isAfter(new Date()) && (
              <Button
                onClick={() => {
                  setHold(schedule, true);
                }}
              >
                Hold
              </Button>
            )}
          {!sourceMoveAppt &&
            !appointment &&
            ['HOLD'].includes(schedule.status) &&
            dayjs(schedule.patientStartAt).isAfter(new Date()) && (
              <Button
                onClick={() => {
                  setHold(schedule, false);
                }}
              >
                Un-Hold
              </Button>
            )}
          {!appointment && !sourceMoveAppt ? (
            <Button onClick={() => onClickRemoveAppointment(schedule)}>
              Remove
            </Button>
          ) : null}
          {sourceMoveAppt &&
            !appointment &&
            dayjs(schedule.patientStartAt).isAfter(new Date()) && (
              <Button
                className="home-week__button"
                onClick={() => doMove(schedule)}
              >
                Move Here
              </Button>
            )}
          {!sourceMoveAppt &&
            setSourceMoveAppt &&
            appointment &&
            !['COMPLETED', 'WAITING_ON_PRACTITIONER', 'IN_PROGRESS'].includes(
              appointment.status
            ) && (
              <Button
                className="home-week__button"
                onClick={() => setSourceMoveAppt(appointment)}
              >
                Move
              </Button>
            )}
          {sourceMoveAppt &&
            setSourceMoveAppt &&
            appointment &&
            appointment._id === sourceMoveAppt._id && (
              <Button
                className="home-week__button"
                onClick={() => setSourceMoveAppt(null)}
              >
                Abort Move
              </Button>
            )}
        </td>
      </tr>
    );
  };

  const renderScheduleTable = () => {
    let dayOfWeek = dayjs(schedulesInWeek[0].practitionerStartAt).day();
    let dayIndex = 0;
    const rows = schedulesInWeek
      .filter((x) => x.practitionerId === prac || !prac)
      .filter((s) => s.status === status || !status)
      .map((el) => {
        if (dayOfWeek !== dayjs(el.practitionerStartAt).day()) {
          dayIndex += 1;
          dayOfWeek = dayjs(el.practitionerStartAt).day();
        }
        const rowClass = dayIndex % 2 === 0 ? 'even' : 'odd';

        return renderTableRow(el, rowClass);
      });

    return (
      <table className="home-week__table">
        <thead>
          <tr>
            <th className="home-week__table-date">Date</th>
            <th className="home-week__table-time">
              Patient
              <br />
              Start Time
            </th>
            <th className="home-week__table-time">
              Patient
              <br />
              End Time
            </th>
            <th className="home-week__table-name">Practitioner</th>
            <th className="home-week__table-status">
              Appointment
              <br />
              Status
            </th>
            <th className="home-week__table-paid">Paid</th>
            <th className="home-week__table-patient">Patient</th>
            <th className="home-week__table-view-appointment">Actions</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  };

  const numberBooked = schedulesInWeek.filter(
    (el) => el.status === ScheduleStatuses.BOOKED
  ).length;
  const percentagedBooked = Math.round(
    schedulesInWeek.length > 0
      ? (numberBooked / schedulesInWeek.length) * 100
      : 0
  );

  const onCloseAlertApiInfo = () => {
    setApiError(null);
  };

  return (
    <div className="home-week">
      {apiError && (
        <AlertInfo
          title="Error"
          label={apiError}
          onClose={onCloseAlertApiInfo}
        />
      )}

      <TextTitle className="mb-10">
        WC {weekStart.format('DD/MM/YYYY')}{' '}
        {schedulesInWeek.length > 0 &&
          `- ${numberBooked} of ${schedulesInWeek.length} booked (${percentagedBooked}%)`}
      </TextTitle>
      {lastUpdated && <TextBody>Last updated: {lastUpdated}</TextBody>}
      {schedulesInWeek.length ? renderScheduleTable() : 'No schedule'}

      {showScheduleRemoveConfirm && (
        <AlertConfirm
          title="Remove Appointment"
          label="Are you sure you want to remove this appointment?"
          cancelButtonLabel="No"
          confirmButtonLabel="Yes"
          onNo={onScheduleRemoveConfirmNo}
          onYes={onScheduleRemoveConfirmYes}
        />
      )}
    </div>
  );
};

Week.propTypes = {
  weekStart: PropTypes.instanceOf(dayjs).isRequired,
  prac: PropTypes.string,
  status: PropTypes.string,
  sourceMoveAppt: PropTypes.func,
  setSourceMoveAppt: PropTypes.func,
};

export default Week;
