import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

export const AppointmentStatuses = {
  BOOKING_HELD: 'BOOKING_HELD',
  BOOKED: 'BOOKED',
  PATIENT_NO_SHOW: 'PATIENT_NO_SHOW',
  IN_PROGRESS: 'IN_PROGRESS',
  WAITING_ON_PRACTITIONER: 'WAITING_ON_PRACTITIONER',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
};

export const appointmentsSlice = createSlice({
  name: 'appointments',
  initialState: {
    entities: [],
  },
  // Redux Toolkit allows us to write "mutating" logic in reducers. It
  // doesn't actually mutate the state because it uses the Immer library,
  // which detects changes to a "draft state" and produces a brand new
  // immutable state based off those changes
  reducers: {
    replaceAppointments: (state, action) => {
      state.entities = action.payload;
    },
    appendAppointments: (state, action) => {
      const payloadIds = [
        ...Object.values(action.payload.map((item) => item._id)),
      ];
      state.entities = state.entities
        .filter((el) => !payloadIds.includes(el._id))
        .concat(action.payload);
    },
    addAppointment: (state, action) => {
      state.entities.push(action.payload);
    },
    replaceAppointment: (state, action) => {
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      if (index !== -1) {
        state.entities[index] = action.payload;
      } else {
        state.entities.push(action.payload);
      }
    },
  },
});

export const {
  replaceAppointments,
  addAppointment,
  replaceAppointment,
  appendAppointments,
} = appointmentsSlice.actions;

const sortAppointmentsByStartAt = (appointments) => {
  return appointments.sort((a, b) =>
    dayjs(a.startAt).isBefore(b.startAt) ? -1 : 1
  );
};

// Selectors ******************************************************************
// The functions below are called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export const selectAllAppointments = (state) => {
  return state.appointments.entities;
};

export const selectBookingHeldAppointment = (state) => {
  return state.appointments.entities.find(
    (el) => el.status === AppointmentStatuses.BOOKING_HELD
  );
};

export const selectUpcomingAppointments = (state) => {
  const filtered = state.appointments.entities.filter((el) => {
    const isBooked = el.status === AppointmentStatuses.BOOKED;
    const isFuture = dayjs(el.startAt).isAfter(dayjs(), 'day');
    return isBooked && isFuture;
  });
  return sortAppointmentsByStartAt(filtered);
};

export const selectTodaysAppointments = (state) => {
  const filtered = state.appointments.entities.filter((el) => {
    const statusMatch = [
      AppointmentStatuses.BOOKED,
      AppointmentStatuses.IN_PROGRESS,
    ].includes(el.status);
    const isToday = dayjs(el.startAt).isSame(dayjs(), 'day');
    return statusMatch && isToday;
  });
  return sortAppointmentsByStartAt(filtered);
};

export const selectActionRequiredAppointments = (state) => {
  const filtered = state.appointments.entities.filter((el) => {
    const statusMatch =
      el.status === AppointmentStatuses.WAITING_ON_PRACTITIONER;
    return statusMatch;
  });
  return sortAppointmentsByStartAt(filtered);
};

export const selectPastAppointments = (state) => {
  const filtered = state.appointments.entities
    .filter((el) => {
      const statuses = [AppointmentStatuses.COMPLETED];
      return statuses.includes(el.status);
    })
    .sort((a, b) => (dayjs(a.startAt).isBefore(b.startAt) ? 1 : -1));
  return filtered;
};

export default appointmentsSlice.reducer;
