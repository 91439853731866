import { configureStore } from '@reduxjs/toolkit';
import patientsReducer from '../slices/patientsSlice.js';
import adminReducer from '../slices/adminSlice.js';
import practitionersReducer from '../slices/practitionersSlice.js';
import medicalHistoriesReducer from '../slices/medicalHistoriesSlice.js';
import menstrualSymptomsReducer from '../slices/menstrualSymptomsSlice.js';
import menopauseSymptomsReducer from '../slices/menopauseSymptomsSlice.js';
import medicalProceduresReducer from '../slices/medicalProceduresSlice.js';
import contraceptionsReducer from '../slices/contraceptionsSlice.js';
import hrtsReducer from '../slices/hrtsSlice.js';
import schedulesReducer from '../slices/schedulesSlice.js';
import appointmentsReducer from '../slices/appointmentsSlice.js';

export default configureStore({
  reducer: {
    patients: patientsReducer,
    admin: adminReducer,
    practitioners: practitionersReducer,
    medicalHistories: medicalHistoriesReducer,
    menstrualSymptoms: menstrualSymptomsReducer,
    menopauseSymptoms: menopauseSymptomsReducer,
    medicalProcedures: medicalProceduresReducer,
    contraceptions: contraceptionsReducer,
    hrts: hrtsReducer,
    schedules: schedulesReducer,
    appointments: appointmentsReducer,
  },
});
