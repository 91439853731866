import { baseApiUrl, jsonGet, jsonPost } from './api.js';

export const createAuditLog = (data) => {
  const url = `${baseApiUrl}/auditlogs`;
  return jsonPost(url, data);
};

export const getPatientAuditLogs = (patientId, appointmentId) => {
  const url = `${baseApiUrl}/auditlogs/patient/${patientId}/appointment/${appointmentId}`;
  return jsonGet(url);
};

export const getPatientFullAuditLogs = (patientId) => {
  const url = `${baseApiUrl}/auditlogs/patient/${patientId}/full`;
  return jsonGet(url);
};
