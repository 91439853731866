/* eslint-disable react/jsx-no-comment-textnodes */
import './Lookup.scss';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import PageHeader from '../elements/PageHeader.js';
import Content from '../elements/Content.js';
import TextTitle from '../elements/TextTitle.js';
import TextBody from '../elements/TextBody.js';
import TextInput from '../elements/form/TextInput.js';
import Button from '../elements/Button.js';
import AlertConfirm from '../elements/alert/Confirm.js';
import LoadingSpinner from '../elements/LoadingSpinner.js';
import {
  appendAppointments,
  AppointmentStatuses,
} from '../../slices/appointmentsSlice.js';
import { selectAllPractitioners } from '../../slices/practitionersSlice.js';
import { getByNumber, deletePatient } from '../../services/api/apiSearch.js';
import { appendSchedules } from '../../slices/schedulesSlice.js';
import { appendPatients } from '../../slices/patientsSlice.js';

const Lookup = () => {
  const { register, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();
  const [hasSearched, setHasSearched] = useState(false);
  const [patients, setPatients] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [pendingDeletePatient, setPendingDeletePatient] = React.useState();

  const practitioners = useSelector(selectAllPractitioners);

  const searchRequest = async (number) => {
    setRequestInProgress(true);
    try {
      const response = await getByNumber(number);

      if (response.schedules) dispatch(appendSchedules(response.schedules));
      if (response.appointments)
        dispatch(appendAppointments(response.appointments));
      if (response.patients) dispatch(appendPatients(response.patients));

      setRequestInProgress(false);
      setPatients(response.patients);
      setSchedules(response.schedules);
      setAppointments(response.appointments);
      setHasSearched(true);
    } catch (err) {
      setRequestInProgress(false);
    }
  };

  const onSubmitForm = (data) => {
    if (requestInProgress) {
      return;
    }

    const { number } = data;
    searchRequest(number);
  };

  const renderTableRow = (patient, appointment, schedule) => {
    let practitionerName = '';
    if (schedule) {
      const practitioner = practitioners.find(
        (el) => el._id.toString() === schedule.practitionerId.toString()
      );

      practitionerName = [
        practitioner.title,
        practitioner.firstName,
        practitioner.lastName,
      ].join(' ');
    }

    const patientDetails = [];
    let price = '-';

    const appointmentStatus = appointment.status;

    const patientName = [
      patient.firstName,
      patient.middleName,
      patient.lastName,
    ].join(' ');

    patientDetails.push(`Name: ${patientName}`);
    patientDetails.push(`Email: ${patient.email}`);
    patientDetails.push(`Tel: ${patient.phoneNumber}`);

    const { stripeSession } = appointment;
    if (stripeSession && stripeSession.payment_status === 'paid') {
      price = stripeSession.amount_total / 100;
    }

    return (
      <tr key={appointment._id}>
        <td>{dayjs(appointment.startAt).format('ddd Do MMM')}</td>
        <td>{dayjs(appointment.startAt).format('hh:mmA')}</td>
        <td>{dayjs(appointment.endAt).format('hh:mmA')}</td>
        <td>{practitionerName}</td>
        <td>{appointmentStatus}</td>
        <td>{price}</td>
        <td>
          {patientDetails.map((el) => {
            return (
              <>
                {el}
                <br />
              </>
            );
          })}
        </td>
        <td className="lookup-patient__table-view-appointment">
          {appointment ? (
            <Link
              to={`/ops/O5CeoNpNYNIGC9kcCRbVnRLNDWMWunVW/appointments/${appointment._id}`}
            >
              View Appointment
            </Link>
          ) : null}
        </td>
      </tr>
    );
  };

  const renderPatientResult = (patient, sortedAppointments) => {
    const patientAppointments = sortedAppointments.filter(
      (el) => el.patientId.toString() === patient._id.toString()
    );

    const tableRows = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const appointment of patientAppointments) {
      const schedule = schedules.find(
        (el) => el._id.toString() === appointment.scheduleId.toString()
      );

      if (appointment.status !== AppointmentStatuses.BOOKING_HELD) {
        tableRows.push(renderTableRow(patient, appointment, schedule));
      }
    }

    const patientName = [
      patient.firstName,
      patient.middleName,
      patient.lastName,
    ].join(' ');

    return (
      <div className="lookup-patient">
        <TextTitle type="sub" className="lookup-patient__label">
          Patient: {patientName} / {patient.email} / {patient.phoneNumber}{' '}
          {patient.email.includes('.deleted') ? ' -- DELETED -- ' : ''}
        </TextTitle>
        <Link to={`/ops/O5CeoNpNYNIGC9kcCRbVnRLNDWMWunVW/audit/${patient._id}`}>
          <Button className="lookup-patient__button">Audit Logs</Button>
        </Link>

        {pendingDeletePatient && (
          <AlertConfirm
            title="Delete Patient"
            label="Are you sure you want to delete this patient? This will change the email address of the patient and block login."
            cancelButtonLabel="No"
            confirmButtonLabel="Yes"
            onNo={() => {
              setPendingDeletePatient(null);
            }}
            onYes={() => {
              deletePatient(patient._id);
              setPendingDeletePatient(null);
            }}
          />
        )}

        {tableRows.length > 0 ? (
          <table className="lookup-patient__table">
            <thead>
              <tr>
                <th className="lookup-patient__table-date">Date</th>
                <th className="lookup-patient__table-time">
                  Patient
                  <br />
                  Start Time
                </th>
                <th className="lookup-patient__table-time">
                  Patient
                  <br />
                  End Time
                </th>
                <th className="lookup-patient__table-name">Practitioner</th>
                <th className="lookup-patient__table-status">
                  Appointment
                  <br />
                  Status
                </th>
                <th className="lookup-patient__table-paid">Paid</th>
                <th className="lookup-patient__table-patient">Patient</th>
                <th className="lookup-patient__table-view-appointment" />
              </tr>
            </thead>
            <tbody>{tableRows}</tbody>
          </table>
        ) : (
          'No appointment bookings for patient'
        )}
      </div>
    );
  };

  const renderSearchResults = () => {
    // Clone before the sort as sort is in place
    const sortedPatients = [...patients].sort((a, b) =>
      a.lastName > b.lastName ? 1 : -1
    );

    const sortedAppointments = [...appointments].sort((a, b) =>
      dayjs(a.startAt).isBefore(b.startAt) ? 1 : -1
    );

    const resultsTables = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const patient of sortedPatients) {
      resultsTables.push(renderPatientResult(patient, sortedAppointments));
    }

    const numberResults = patients.length;

    return (
      <>
        <TextBody>Found {numberResults} patient(s)</TextBody>
        {numberResults ? resultsTables : 'No patients found'}
      </>
    );
  };

  return (
    <>
      <PageHeader />
      <div className="lookup">
        <Content fullWidth>
          <form onSubmit={handleSubmit(onSubmitForm)} className="lookup-form">
            <TextInput
              label="Lookup patient by number"
              type="number"
              name="number"
              error={errors.number}
              registerRef={register({
                required: 'This field is required',
              })}
              className="lookup-form__number"
            />

            <div className="lookup-form__submit">
              {requestInProgress === true ? (
                <LoadingSpinner />
              ) : (
                <Button type="submit">Find</Button>
              )}
            </div>
          </form>

          {hasSearched && renderSearchResults()}
        </Content>
      </div>
    </>
  );
};

export default Lookup;
