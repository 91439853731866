import './AppRouter.scss';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import UnauthenticatedRoute from './UnauthenticatedRoute.js';
import AuthenticationRequiredRoute from './AuthenticationRequiredRoute.js';
import ScrollToTop from './ScrollToTop.js';
import LoadingSpinner from '../components/elements/LoadingSpinner.js';
import Activate from '../components/auth/activate/Activate.js';
import Login from '../components/auth/login/Login.js';
import ForgottenPassword from '../components/auth/forgottenPassword/ForgottenPassword.js';
import Home from '../components/home/Home.js';
import HomeSchedules from '../components/home/Schedules.js';
import HomeSchedulesAdd from '../components/home/SchedulesAdd.js';
import Account from '../components/account/Account.js';
import Search from '../components/search/Search.js';
import Lookup from '../components/search/Lookup.js';
import AppointmentView from '../components/appointment/View.js';
import UnsupportedBrowser from '../components/general/UnsupportedBrowser.js';
import PageNotFound from '../components/general/PageNotFound.js';
import { fetchAdmin, selectAdminFetched } from '../slices/adminSlice.js';
import { isSupportedBrowser } from '../services/browser.js';
import FullAuditLogs from '../components/general/FullAuditLogs.js';

const AppRouter = () => {
  const patientFetched = useSelector(selectAdminFetched);
  const dispatch = useDispatch();
  const showUnsupportedBrowser = !isSupportedBrowser();

  // Load the initial account state
  useEffect(() => {
    if (showUnsupportedBrowser) {
      return;
    }

    dispatch(fetchAdmin());
  }, [dispatch, showUnsupportedBrowser]);

  const renderLoading = () => {
    return <LoadingSpinner fullscreen />;
  };

  const renderAuthenticationRequiredRoutes = () => {
    return (
      <Switch>
        {/* Public routes - authentication not required but should logout the user
        first if they are logged in */}
        <UnauthenticatedRoute
          exact
          path="/ops/O5CeoNpNYNIGC9kcCRbVnRLNDWMWunVW/activate-account"
          component={Activate}
        />
        <UnauthenticatedRoute
          exact
          path="/ops/O5CeoNpNYNIGC9kcCRbVnRLNDWMWunVW/login"
          component={Login}
        />
        <UnauthenticatedRoute
          exact
          path="/ops/O5CeoNpNYNIGC9kcCRbVnRLNDWMWunVW/forgotten-password"
          component={ForgottenPassword}
        />
        {/* Private routes - authentication required */}
        <AuthenticationRequiredRoute
          exact
          path="/ops/O5CeoNpNYNIGC9kcCRbVnRLNDWMWunVW/"
          component={Home}
        />
        <AuthenticationRequiredRoute
          exact
          path="/ops/O5CeoNpNYNIGC9kcCRbVnRLNDWMWunVW/schedules"
          component={HomeSchedules}
        />
        <AuthenticationRequiredRoute
          exact
          path="/ops/O5CeoNpNYNIGC9kcCRbVnRLNDWMWunVW/schedules/add"
          component={HomeSchedulesAdd}
        />
        <AuthenticationRequiredRoute
          exact
          path="/ops/O5CeoNpNYNIGC9kcCRbVnRLNDWMWunVW/account"
          component={Account}
        />
        <AuthenticationRequiredRoute
          exact
          path="/ops/O5CeoNpNYNIGC9kcCRbVnRLNDWMWunVW/search"
          component={Search}
        />
        <AuthenticationRequiredRoute
          exact
          path="/ops/O5CeoNpNYNIGC9kcCRbVnRLNDWMWunVW/lookup"
          component={Lookup}
        />
        <AuthenticationRequiredRoute
          exact
          path="/ops/O5CeoNpNYNIGC9kcCRbVnRLNDWMWunVW/appointments/:appointmentId"
          component={AppointmentView}
        />
        <AuthenticationRequiredRoute
          exact
          path="/ops/O5CeoNpNYNIGC9kcCRbVnRLNDWMWunVW/audit/:patientId"
          component={FullAuditLogs}
        />
        <Route component={PageNotFound} />
      </Switch>
    );
  };

  let content;
  if (showUnsupportedBrowser) {
    content = <UnsupportedBrowser />;
  } else if (patientFetched) {
    content = renderAuthenticationRequiredRoutes();
  } else {
    content = renderLoading();
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      {content}
    </BrowserRouter>
  );
};

export default AppRouter;
