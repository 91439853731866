import './Content.scss';
import React from 'react';
import PropTypes from 'prop-types';

const Content = (props) => {
  const { className, children, fullWidth, ...restOfProps } = props;

  const classes = ['content'];

  if (className.length > 0) {
    classes.push(className);
  }

  if (fullWidth) {
    classes.push('content--full-width');
  }

  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restOfProps}
      className={classes.join(' ')}
    >
      {children}
    </div>
  );
};

Content.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
};

Content.defaultProps = {
  className: '',
  fullWidth: false,
};

export default Content;
