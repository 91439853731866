/* eslint-disable react/forbid-prop-types */
import './AuditLogs.scss';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import JSONPretty from 'react-json-pretty';
import AlertError from '../elements/alert/Error.js';
import { getPatientAuditLogs } from '../../services/api/apiAuditLogs.js';
import { selectAllPractitioners } from '../../slices/practitionersSlice.js';

const AuditLogs = (props) => {
  const { appointment, patient } = props;
  const [auditLogs, setAuditLogs] = useState(null);
  const [apiError, setApiError] = useState();
  const practitioners = useSelector(selectAllPractitioners);

  const getPatientAuditLogsRequest = async () => {
    try {
      const response = await getPatientAuditLogs(patient._id, appointment._id);
      setAuditLogs(response.auditLogs);
    } catch (err) {
      setApiError(
        'There was an error loading the audit log. Please try again.'
      );
    }
  };

  useEffect(() => {
    getPatientAuditLogsRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment]);

  const renderPractitionerAppointmentUpdated = (eventDetails) => {
    const eventDetailsCopy = { ...eventDetails };
    const { attributes = {} } = eventDetailsCopy;
    const {
      practitionerNotesForPatient = null,
      practitionerNotesForGpLetter = null,
      practitionerNotesForClinic = null,
      ...restOfEventDetails
    } = attributes;

    return (
      <>
        {Object.keys(restOfEventDetails).length > 0 && (
          <JSONPretty id="json-pretty" data={restOfEventDetails} />
        )}
        {practitionerNotesForPatient !== null && (
          <div className="appointment-audit-logs__table-formatted-text">
            <b>practitionerNotesForPatient:</b> Updated
          </div>
        )}

        {practitionerNotesForGpLetter !== null && (
          <div className="appointment-audit-logs__table-formatted-text">
            <b>practitionerNotesForGpLetter:</b> Updated
          </div>
        )}

        {practitionerNotesForClinic !== null && (
          <div className="appointment-audit-logs__table-formatted-text">
            <b>practitionerNotesForClinic:</b> Updated
          </div>
        )}
      </>
    );
  };

  const renderAdminAppointmentUpdated = (eventDetails) => {
    const eventDetailsCopy = { ...eventDetails };
    const { attributes = {} } = eventDetailsCopy;
    const { practitionerNotesForPatient = null, ...restOfEventDetails } =
      attributes;

    return (
      <>
        {Object.keys(restOfEventDetails).length > 0 && (
          <JSONPretty id="json-pretty" data={restOfEventDetails} />
        )}
        {practitionerNotesForPatient !== null && (
          <div className="appointment-audit-logs__table-formatted-text">
            <b>practitionerNotesForPatient:</b> Updated
          </div>
        )}
      </>
    );
  };

  const getFormattedEventDetails = (eventType, eventDetails = {}) => {
    const returnEmptyText = '[none]';

    if (Object.keys(eventDetails).length === 0) {
      return returnEmptyText;
    }

    switch (eventType) {
      case 'PATIENT_APPOINTMENT_BOOKED':
      case 'ADMIN_VIEWED_APPOINTMENT':
      case 'PRACTITIONER_VIEWED_APPOINTMENT':
        return returnEmptyText;
      case 'PRACTITIONER_APPOINTMENT_UPDATED': {
        return renderPractitionerAppointmentUpdated(eventDetails);
      }
      case 'ADMIN_APPOINTMENT_UPDATED': {
        return renderAdminAppointmentUpdated(eventDetails);
      }
      case 'PATIENT_EMAIL_SENT': {
        // Remove mergeVars
        const { mergeVars, ...rest } = eventDetails;
        return <JSONPretty id="json-pretty" data={rest} />;
      }
      default:
        return <JSONPretty id="json-pretty" data={eventDetails} />;
    }
  };

  const getFormattedUserDetails = (userBy) => {
    const userType = userBy ? userBy.userType : 'SYSTEM';
    const userId = userBy ? userBy._id : null;

    let userName = '';
    switch (userType) {
      case 'PATIENT': {
        userName = [
          patient.firstName,
          patient.middleName,
          patient.lastName,
        ].join(' ');
        break;
      }
      case 'PRACTITIONER': {
        const practitioner = practitioners.find((el) => {
          return el._id.toString() === userId.toString();
        });
        if (practitioner) {
          userName = [
            practitioner.title,
            practitioner.firstName,
            practitioner.lastName,
          ].join(' ');
        }
        break;
      }
      default:
        userName = '';
    }

    return (
      <>
        {userType}
        <br />
        <br />
        {userName}
      </>
    );
  };

  const getFormattedIpBrowserDetails = (userBy, ip, userAgent) => {
    const userType = userBy ? userBy.userType : null;

    if (userType === null) {
      return '';
    }

    return (
      <>
        {ip || ''}
        <br />
        <br />
        {userAgent || ''}
      </>
    );
  };

  const renderTableRow = (auditLog) => {
    const { userBy, eventDetails, userAgent, ip } = auditLog;

    return (
      <tr key={auditLog._id}>
        <td>
          {auditLog.eventType}
          <br />
          <br />
          {dayjs(auditLog.createdAt).format('YYYY-MM-DD HH:mm:ss')}
        </td>
        <td>{getFormattedUserDetails(userBy)}</td>
        <td>{getFormattedEventDetails(auditLog.eventType, eventDetails)}</td>
        <td>{getFormattedIpBrowserDetails(userBy, ip, userAgent)}</td>
      </tr>
    );
  };

  const onCloseAlertError = () => {
    setApiError(null);
  };

  const renderAuditLogs = () => {
    if (!auditLogs || auditLogs.length === 0) {
      return 'No audit logs for patient';
    }

    const sortedAuditLogs = [...auditLogs].sort((a, b) =>
      dayjs(a.createdAt).isBefore(b.createdAt) ? 1 : -1
    );

    const rows = sortedAuditLogs.map((el) => {
      return renderTableRow(el);
    });

    return (
      <table className="appointment-audit-logs__table">
        <thead>
          <tr>
            <th className="appointment-audit-logs__table-event">
              Event Type / Timestamp
            </th>
            <th className="appointment-audit-logs__table-user">By User</th>
            <th className="appointment-audit-logs__table-details">
              Event Details
            </th>
            <th className="appointment-audit-logs__table-ip">IP / Browser</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  };

  return (
    <>
      {renderAuditLogs()}
      {apiError && <AlertError label={apiError} onClose={onCloseAlertError} />}
    </>
  );
};

AuditLogs.propTypes = {
  appointment: PropTypes.object.isRequired,
  patient: PropTypes.object.isRequired,
};

export default AuditLogs;
