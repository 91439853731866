import './Schedules.scss';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Select, MenuItem, InputLabel } from '@mui/material';
import { CSVLink } from 'react-csv';
import PageHeader from '../elements/PageHeader.js';
import Content from '../elements/Content.js';
import Button from '../elements/Button.js';
import Week from './Week.js';

import {
  selectAllSchedules,
  getMonthsSchedules,
} from '../../slices/schedulesSlice.js';
import { selectAllActivePractitioners } from '../../slices/practitionersSlice.js';
import AlertInfo from '../elements/alert/Info.js';

dayjs.extend(relativeTime);

const Schedules = () => {
  const schedules = useSelector(selectAllSchedules);
  const [apiError, setApiError] = useState();
  const dispatch = useDispatch();
  const statuses = [...new Set(schedules.map((x) => x.status))];
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [visualView] = React.useState(false);
  const [pracFilter, setPracFilter] = React.useState('');
  const [statusFilter, setStatusFilter] = React.useState('');

  const [sourceMoveAppt, setSourceMoveAppt] = React.useState(null);

  useEffect(() => {
    const getMonthSchedulesOnLoad = async () => {
      const isoDate = new Date(
        `${selectedMonth + 1} 01 ${selectedYear}`
      ).toISOString();
      await getMonthsSchedules(isoDate, dispatch, setApiError);
    };
    getMonthSchedulesOnLoad();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const practitioners = useSelector(selectAllActivePractitioners).map(
    (practitioner) => ({
      name: [
        practitioner.title,
        practitioner.firstName,
        practitioner.lastName,
      ].join(),
      id: practitioner._id,
    })
  );

  const renderYearTabs = () => {
    const onClickYearTab = async (year) => {
      const isoDate = new Date(`${selectedMonth + 1} 01 ${year}`).toISOString();
      await getMonthsSchedules(isoDate, dispatch, setApiError);
      setSelectedYear(year);
    };

    // Clinic went live Nov 2020
    const startYear = 2020;

    let endYear;
    if (schedules.length > 0) {
      endYear = dayjs(
        schedules[schedules.length - 1].practitionerStartAt
      ).year();
    } else {
      endYear = dayjs().year();
    }

    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }

    const tabs = years.map((el) => {
      const classes = ['schedules__tab'];

      if (el === selectedYear) {
        classes.push('schedules__tab--active');
      }

      return (
        <div
          className={classes.join(' ')}
          key={el}
          onClick={() => onClickYearTab(el)}
        >
          {el}
        </div>
      );
    });

    return <div className="schedules__tabs">{tabs}</div>;
  };

  const renderMonthTabs = () => {
    const onClickMonthTab = async (month) => {
      const isoDate = new Date(`${month + 1} 01 ${selectedYear}`).toISOString();
      await getMonthsSchedules(isoDate, dispatch, setApiError);
      setSelectedMonth(month);
    };

    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const tabs = months.map((el, i) => {
      const classes = ['schedules__tab'];

      if (i === selectedMonth) {
        classes.push('schedules__tab--active');
      }

      return (
        <div
          className={classes.join(' ')}
          key={el}
          onClick={() => onClickMonthTab(i)}
        >
          {el}
        </div>
      );
    });

    return (
      <div className="schedules__tabs schedules__tabs--months">{tabs}</div>
    );
  };

  const getDayOfWeek = (dayjsDate) => {
    const jsDate = dayjsDate.toDate();
    // 0 - 6 (mon - sun)
    return (jsDate.getDay() + 6) % 7;
  };

  const renderActions = () => (
    <div className="schedules__actions">
      <Link
        to="/ops/O5CeoNpNYNIGC9kcCRbVnRLNDWMWunVW/schedules/add"
        className="login-actions"
      >
        <Button color="primary-outline">Add Schedules</Button>
      </Link>
      <CSVLink
        data={schedules}
        filename={'export-schedules.csv'}
        target="_blank"
      >
        <Button color="primary-outline">Export</Button>
      </CSVLink>
      ;
    </div>
  );

  const renderStats = () => {
    const monthSchedules = schedules.filter((x) => {
      const t = dayjs(x.practitionerStartAt);
      return t.month() === selectedMonth && t.year() === selectedYear;
    });

    const obj = {};
    const summary = monthSchedules.reduce((a, c) => {
      if (!a[c.status]) a[c.status] = 1;
      else a[c.status] += 1;
      return a;
    }, obj);

    // Temp testing
    console.log(monthSchedules);

    const arr = Object.keys(summary).map((k) => `${k}: ${summary[k]}`);

    return `Monthly stats: ${arr.join(', ')} :: Total: ${
      monthSchedules.length
    }`;
  };
  /* const renderCostStats = () => {
    const monthSchedules = schedules.filter((x) => {
      const t = dayjs(x.practitionerStartAt);
      return t.month() === selectedMonth && t.year() === selectedYear;
    });

    const obj = {};
    const summary = monthSchedules.reduce((a, c) => {
      if (!a[c.status]) a[c.status] = 1;
      else a[c.status] += 1;
      return a;
    }, obj);

    // Temp testing
    console.log(monthSchedules);

    const arr = Object.keys(summary).map((k) => `${k}: ${summary[k]}`);

    return `Monthly stats: ${arr.join(', ')} :: Total: ${
      monthSchedules.length
    }`;
  }; */

  const renderSchedulesForMonth = () => {
    const monthStart = dayjs()
      .year(selectedYear)
      .month(selectedMonth)
      .startOf('month');

    const monthEnd = monthStart.endOf('month');

    // Over draw the month so we display full weeks (this will take us into
    // the previous and next month)
    const firstDate = monthStart.subtract(getDayOfWeek(monthStart), 'days');
    const lastDate = monthEnd
      // Display a full week as last day of the month is unlikely to be a Sunday
      .add(6 - getDayOfWeek(monthEnd), 'days');

    let currentDate = firstDate;
    const weeks = [];
    while (currentDate.isBefore(lastDate)) {
      weeks.push(
        <Week
          weekStart={currentDate}
          key={currentDate.toISOString()}
          prac={pracFilter}
          status={statusFilter}
          sourceMoveAppt={sourceMoveAppt}
          setSourceMoveAppt={setSourceMoveAppt}
        />
      );
      currentDate = currentDate.add(1, 'week');
    }

    return weeks;
  };

  return (
    <>
      <PageHeader />
      <div className="schedules">
        <Content fullWidth>
          {visualView && (
            <div>
              <div className="schedules__header">{renderActions()}</div>
            </div>
          )}

          {!visualView && (
            <div>
              <div className="schedules__header">
                <div>
                  {renderYearTabs()}
                  {renderMonthTabs()}
                </div>
                {renderActions()}
              </div>
              {renderStats()}
              <div className="schedules__row">
                <InputLabel id="sched-gp-label">Practitioner</InputLabel>
                <Select
                  labelId="sched-gp-labell"
                  value={pracFilter}
                  label="Practitioner"
                  onChange={(e) => {
                    setPracFilter(e.target.value);
                  }}
                >
                  <MenuItem value="">(All)</MenuItem>
                  {practitioners.map((p) => (
                    <MenuItem key={p.id} value={p.id}>
                      {p.name}
                    </MenuItem>
                  ))}
                </Select>

                <InputLabel id="sched-status-label">Sched. Status</InputLabel>
                <Select
                  labelId="sched-status-label"
                  value={statusFilter}
                  label="Sched. Status"
                  onChange={(e) => {
                    setStatusFilter(e.target.value);
                  }}
                >
                  <MenuItem value="">(All)</MenuItem>
                  {statuses.map((p) => (
                    <MenuItem key={p} value={p}>
                      {p}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              {apiError && (
                <AlertInfo
                  title="Error"
                  label={apiError}
                  onClose={() => setApiError(null)}
                />
              )}
              {renderSchedulesForMonth()}
            </div>
          )}
        </Content>
      </div>
    </>
  );
};

export default Schedules;
