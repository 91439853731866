import './PageHeader.scss';
import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration.js';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import NavBar from '../navigation/NavBar.js';
import Logo from './Logo.js';
import TextTitle from './TextTitle.js';
import { selectAdminIsAuthenticated } from '../../slices/adminSlice.js';

dayjs.extend(duration);

const PageHeader = (props) => {
  const { title, showBack, onClickBack } = props;
  const history = useHistory();
  const adminIsAuthenticated = useSelector(selectAdminIsAuthenticated);

  const renderLoggedInHeader = () => {
    return (
      <>
        <NavBar />
      </>
    );
  };

  const onClickLogo = () => {
    history.push('/ops/O5CeoNpNYNIGC9kcCRbVnRLNDWMWunVW');
  };

  let pageTitle = title;
  if (showBack && pageTitle.length === 0) {
    pageTitle = <span className="page-header__back-title">Back</span>;
  }

  return (
    <div className="page-header">
      <div className="page-header__left">
        {showBack && !!onClickBack ? (
          <ArrowBackIosIcon
            className="page-header__back"
            onClick={onClickBack}
          />
        ) : null}
        <TextTitle className="page-header__title" onClick={onClickBack}>
          {pageTitle}
        </TextTitle>
        <button
          type="button"
          className="page-header__logo"
          onClick={onClickLogo}
        >
          <Logo />
        </button>
      </div>
      {adminIsAuthenticated && renderLoggedInHeader()}
    </div>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string,
  showBack: PropTypes.bool,
  onClickBack: PropTypes.func,
};

PageHeader.defaultProps = {
  title: '',
  showBack: false,
  onClickBack: null,
};

export default PageHeader;
