import './Button.scss';
import React from 'react';
import PropTypes from 'prop-types';

const Button = (props) => {
  const { color, size, className, children, type, ...rest } = props;

  const classes = ['button'];
  classes.push(`button--color-${color}`);
  classes.push(`button--size-${size}`);

  if (className.length > 0) {
    classes.push(className);
  }

  return (
    <button
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={classes.join(' ')}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'primary-outline',
    'primary-link',
    'secondary',
    'secondary-outline',
    'danger-outline',
    'disabled',
  ]),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
};

Button.defaultProps = {
  color: 'primary',
  size: 'medium',
  className: '',
  type: 'button',
};

export default Button;
