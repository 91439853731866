import './TextTitle.scss';
import React from 'react';
import PropTypes from 'prop-types';

const TextTitle = (props) => {
  const { type, className, children } = props;

  const classes = ['text-title'];
  classes.push(`text-title--${type}`);

  if (className.length > 0) {
    classes.push(className);
  }

  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      className={classes.join(' ')}
    >
      {children}
    </div>
  );
};

TextTitle.propTypes = {
  type: PropTypes.oneOf(['main', 'sub']),
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
};

TextTitle.defaultProps = {
  type: 'main',
  className: '',
  children: null,
};

export default TextTitle;
