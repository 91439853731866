import Bowser from 'bowser';

const browser = Bowser.getParser(window.navigator.userAgent);

// We only support Chrome/Firefox for admin portal - this is simply to reduce
// development overhead when testing
export const isSupportedBrowser = () => {
  // Limited support for production for easier testing
  const supported = ['Chrome', 'Firefox'];

  if (window.location.hostname !== 'clinic.healthandher.com') {
    supported.push('Safari');
  }

  const { name } = browser.getBrowser();

  return supported.includes(name);
};
