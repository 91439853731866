import './TextArea.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import FormFieldError from './FieldError.js';

const TextArea = (props) => {
  const { label, name, placeholder, className, registerRef, error } = props;

  const id = uuidv4();
  const classes = ['form-text-area'];

  let errorLabel = '';
  if (Object.keys(error).length > 0) {
    classes.push('form-text-area--error');

    if (error.message.length > 0) {
      errorLabel = error.message;
    } else {
      errorLabel = 'Invalid input';
    }
  }

  if (className.length > 0) {
    classes.push(className);
  }

  return (
    <div className={classes.join(' ')}>
      <label className="form-text-area__label" htmlFor={id}>
        {label.length > 0 && label}
        <textarea
          className="form-text-area__field"
          id={id}
          name={name}
          ref={registerRef}
          placeholder={placeholder}
        />
        {error && errorLabel.length > 0 ? (
          <FormFieldError label={errorLabel} />
        ) : null}
      </label>
    </div>
  );
};

TextArea.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  registerRef: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
};

TextArea.defaultProps = {
  error: {},
  placeholder: '',
  className: '',
};

export default TextArea;
