import './SchedulesAdd.scss';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
// eslint-disable-next-line no-unused-vars
import localeEs from 'dayjs/locale/es';
import relativeTime from 'dayjs/plugin/relativeTime';
import PageHeader from '../elements/PageHeader.js';
import TextTitle from '../elements/TextTitle.js';
import Content from '../elements/Content.js';
import Button from '../elements/Button.js';
import { selectAllActivePractitioners } from '../../slices/practitionersSlice.js';
import { add } from '../../services/api/apiSchedule.js';
import { fetchAdmin } from '../../slices/adminSlice.js';

dayjs.extend(relativeTime);

const SchedulesAdd = () => {
  const practitioners = useSelector(selectAllActivePractitioners);
  const dispatch = useDispatch();

  const [settings, setSettings] = React.useState({
    days: [],
    apptLength: 50,
    patientLength: 35,
    patientStart: 0,
    numberToCreate: 1,
    firstTime: '08:00',
    practitionerId: practitioners.length > 0 ? practitioners[0]._id : null,
  });
  const [selectedDate, setSelectedDate] = React.useState(
    dayjs().format('YYYY-MM-DD')
  );
  const [proposedSchedules, setProposedSchedules] = React.useState([]);
  const [requestInProgress, setRequestInProgress] = React.useState(false);

  const history = useHistory();

  const generateSchedules = () => {
    const scheds = [];
    settings.days.forEach((day) => {
      let first = dayjs(`${day} ${settings.firstTime}`);
      for (let i = 0; i < settings.numberToCreate; i++) {
        const schedule = {
          practitionerStartAt: first.toISOString(),
          practitionerEndAt: first
            .add(settings.apptLength, 'minute')
            .toISOString(),
          patientStartAt: first
            .add(settings.patientStart, 'minute')
            .toISOString(),
          patientEndAt: first
            .add(settings.patientStart + settings.patientLength, 'minute')
            .toISOString(),
          status: 'AVAILABLE',
          type: 'INITIAL',
          practitionerId: settings.practitionerId,
        };
        scheds.push(schedule);
        first = first.add(settings.apptLength, 'minute');
      }
    });

    console.log(scheds);

    setProposedSchedules(scheds);
  };

  const importSchedules = async () => {
    setRequestInProgress(true);
    try {
      const promises = proposedSchedules.map(async (schedule) => {
        await add(schedule);
      });

      // dispatch(addSchedule(schedule));
      await Promise.all(promises);
    } catch (err) {
      setRequestInProgress(false);
    }
    dispatch(fetchAdmin());
    history.push('/ops/O5CeoNpNYNIGC9kcCRbVnRLNDWMWunVW/schedules');
  };

  const renderSchedule = (sched) => {
    const practitioner = practitioners.find(
      (x) => x._id === sched.practitionerId
    );
    const practitionerName = practitioner
      ? `${practitioner.title} ${practitioner.firstName} ${practitioner.lastName}`
      : '(unknown)';
    return (
      <div className="add-schedules__table_row">
        <div className="add-schedules__table_cell">
          {dayjs(sched.practitionerStartAt).format('YYYY-MM-DD')}
        </div>
        <div className="add-schedules__table_cell">
          {dayjs(sched.practitionerStartAt).format('HH:mm')}
        </div>
        <div className="add-schedules__table_cell">
          {dayjs(sched.practitionerEndAt).format('HH:mm')}
        </div>
        <div className="add-schedules__table_cell">
          {dayjs(sched.patientStartAt).format('HH:mm')}
        </div>
        <div className="add-schedules__table_cell">
          {dayjs(sched.patientEndAt).format('HH:mm')}
        </div>
        <div className="add-schedules__table_cell">{sched.status}</div>
        <div className="add-schedules__table_wide_cell">{practitionerName}</div>
      </div>
    );
  };

  return (
    <>
      <PageHeader />

      <div className="add-schedules">
        {proposedSchedules.length === 0 ? (
          <Content fullWidth>
            <TextTitle>Add Schedules for Practitioner</TextTitle>
            <div className="add-schedules__row">
              <div className="add-schedules__label">Choose practitioner:</div>
              <select
                className="form-dob__select form-dob__day"
                name="dob.day"
                onChange={(e) => {
                  setSettings({ ...settings, practitionerId: e.target.value });
                }}
                // ref={register}
              >
                {practitioners.map((practitioner) => {
                  return (
                    <option key={practitioner._id} value={practitioner._id}>
                      {practitioner.title} {practitioner.firstName}{' '}
                      {practitioner.lastName}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="add-schedules__row">
              <div className="add-schedules__label">Select days:</div>
            </div>
            <ul className="add-schedules__list">
              {settings.days.length ? (
                settings.days.map((day) => <li key={day}>{day}</li>)
              ) : (
                <div>No dates selected</div>
              )}
            </ul>
            <div>
              <input
                type="date"
                value={selectedDate}
                min={dayjs().format('YYYY-MM-DD')}
                onChange={(e) => {
                  setSelectedDate(e.target.value);
                }}
              />
              <Button
                className="add-schedules__button"
                onClick={() => {
                  if (!settings.days.includes(selectedDate))
                    setSettings({
                      ...settings,
                      days: [...settings.days, selectedDate],
                    });
                }}
              >
                Add
              </Button>
            </div>
            <div className="add-schedules__row">
              <div className="add-schedules__label">First time:</div>
              <input
                type="time"
                value={settings.firstTime}
                onChange={(e) =>
                  setSettings({ ...settings, firstTime: e.target.value })
                }
              />
            </div>
            <div className="add-schedules__row">
              <div className="add-schedules__label">
                Appointment Length (minutes)
              </div>
              <input
                type="number"
                min="0"
                step="5"
                value={settings.apptLength}
                max="120"
                onChange={(e) =>
                  setSettings({ ...settings, apptLength: e.target.value })
                }
              />
            </div>
            <div className="add-schedules__row">
              <div className="add-schedules__label">
                Patient Length (minutes)
              </div>
              <input
                type="number"
                min="0"
                step="5"
                value={settings.patientLength}
                max="60"
                onChange={(e) =>
                  setSettings({ ...settings, patientLength: e.target.value })
                }
              />
            </div>
            <div className="add-schedules__row">
              <div className="add-schedules__label">
                Patient Start Offset (minutes)
              </div>
              <input
                type="number"
                min="0"
                step="5"
                value={settings.patientStart}
                max="60"
                onChange={(e) =>
                  setSettings({ ...settings, patientStart: e.target.value })
                }
              />
            </div>
            <div className="add-schedules__row">
              <div className="add-schedules__label">
                Number of Schedules to Create
              </div>
              <input
                type="number"
                min="1"
                max="10"
                step="1"
                value={settings.numberToCreate}
                onChange={(e) =>
                  setSettings({ ...settings, numberToCreate: e.target.value })
                }
              />
            </div>
            <div className="add-schedules__action_container">
              <div className="add-schedules__action_label">
                Click to generate schedules:
              </div>
              <div>
                <Button
                  disabled={settings.days.length === 0}
                  color={settings.days.length === 0 ? 'disabled' : 'primary'}
                  className="add-schedules__action_button"
                  onClick={() => generateSchedules()}
                >
                  Generate
                </Button>
              </div>
            </div>
          </Content>
        ) : (
          <Content fullWidth>
            <div className="add-schedues__action_container">
              <TextTitle>Validate Schedules before import</TextTitle>
              Number of slots generated: {proposedSchedules.length}
              <div className="add-schedules__table">
                <div className="add-schedules__table_row">
                  <div className="add-schedules__table_header">Date</div>
                  <div className="add-schedules__table_header">Prac. Start</div>
                  <div className="add-schedules__table_header">Prac. End</div>
                  <div className="add-schedules__table_header">
                    Patient Start
                  </div>
                  <div className="add-schedules__table_header">Patient End</div>
                  <div className="add-schedules__table_header">Status</div>
                  <div className="add-schedules__table_wide_header">
                    Practitioner
                  </div>
                </div>
                {proposedSchedules.map((proposed) => renderSchedule(proposed))}
              </div>
              <div>
                Warning: Schedules are NOT validated against existing schedules
                for overlap!
              </div>
              <Button
                disabled={requestInProgress}
                color={requestInProgress ? 'disabled' : 'primary'}
                className="add-schedules__action_button"
                onClick={() => importSchedules()}
              >
                Import Schedules
              </Button>
            </div>
          </Content>
        )}
      </div>
    </>
  );
};

export default SchedulesAdd;
