/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAdminIsAuthenticated } from '../slices/adminSlice.js';
import './AppRouter.scss';

const packageJson = require('../../package.json');

// Wrap a Route with an authentication check and redirect to /login if the
// user isn't authenticated
function AuthenticationRequiredRoute({ component: Component, ...rest }) {
  const userIsAuthenticated = useSelector(selectAdminIsAuthenticated);

  const render = (props) => {
    if (userIsAuthenticated === true) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return (
        <div>
          <Component {...props} />
          <div className="authRoute__version">
            ClinOps Version: {packageJson.version}
          </div>
        </div>
      );
    }
    return (
      <Redirect
        to={{
          pathname: '/ops/O5CeoNpNYNIGC9kcCRbVnRLNDWMWunVW/login',
        }}
      />
    );
  };

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route {...rest} render={render} />;
}

AuthenticationRequiredRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.instanceOf(React.Component),
    PropTypes.func,
  ]).isRequired,
};

export default AuthenticationRequiredRoute;
