import './Info.scss';
import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import Button from '../Button.js';
import TextTitle from '../TextTitle.js';
import TextBody from '../TextBody.js';

const Info = (props) => {
  const { onClose, title, label } = props;

  return (
    <div className="alert-info" onClick={onClose}>
      <div className="alert-info__content">
        <div className="alert-info__header">
          <TextTitle type="sub" className="alert-info__label">
            {title}
          </TextTitle>
          <CloseIcon className="alert-info__close" onClick={onClose} />
        </div>
        <TextBody>{label}</TextBody>

        <Button onClick={onClose}>OK</Button>
      </div>
    </div>
  );
};

Info.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Info;
