import { baseApiUrl, jsonPost, jsonDelete } from './api.js';

export const search = (term) => {
  const url = `${baseApiUrl}/search`;
  return jsonPost(url, { term });
};

export const getByNumber = (number) => {
  const url = `${baseApiUrl}/search/number`;
  return jsonPost(url, { number });
};

export const deletePatient = (patientId) => {
  const url = `${baseApiUrl}/admin/patient/${patientId}`;
  return jsonDelete(url);
};
