/* eslint-disable react/forbid-prop-types */
import './Completed.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { convertFromRaw } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import linkifyHtml from 'linkifyjs/html';
import { useForm } from 'react-hook-form';
import Button from '../elements/Button.js';
import {
  AppointmentStatuses,
  replaceAppointment,
} from '../../slices/appointmentsSlice.js';
import LoadingSpinner from '../elements/LoadingSpinner.js';
import { selectAllPractitioners } from '../../slices/practitionersSlice.js';

import { patch, getLetter } from '../../services/api/apiAppointment.js';
import TextArea from '../elements/form/TextArea.js';
import AlertError from '../elements/alert/Error.js';

const Completed = (props) => {
  const { appointment } = props;
  const dispatch = useDispatch();
  const practitioners = useSelector(selectAllPractitioners);

  const [apiError, setApiError] = useState();
  const [loadingGpLetter, setLoadingGpLetter] = useState(false);
  const [showNewPatientNoteForm, setShowNewPatientNoteForm] = useState(false);

  const practitionerNotesForPatient =
    appointment.practitionerNotesForPatient || [];
  const gpLetter = appointment.practitionerNotesForGpLetter || '';
  const practitionerNotesForClinic =
    appointment.practitionerNotesForClinic || [];

  const { register, handleSubmit, errors } = useForm({
    practitionerNotesForPatient: '',
  });

  const patchRequest = async (data) => {
    try {
      const response = await patch(appointment._id, data);
      dispatch(replaceAppointment(response.appointment));
    } catch (err) {
      setApiError(
        'There was an error updating the appointment. Please try again.'
      );
    }
  };

  const onCloseAlertError = () => {
    setApiError(null);
  };

  const renderAddNewPatientNoteForm = () => {
    const onSubmitForm = async (data) => {
      await patchRequest({
        attributesToUpdate: data,
      });

      setShowNewPatientNoteForm(false);
    };

    return (
      <>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <TextArea
            label="Patient Notes; Add a new entry to the patient notes. This will be appended to the end of the existing patient notes. The date and time will be automatically added."
            type="text"
            id="appointment-view-appointment-form-practitionerNotesForPatient"
            name="practitionerNotesForPatient"
            error={errors.practitionerNotesForPatient}
            registerRef={register()}
            className="appointment-view-appointment-form-practitionerNotesForPatient"
          />
          <div className="appointment-view-appointment-form__actions">
            <Button type="submit">Publish Patient Note</Button>
          </div>
        </form>

        {apiError && (
          <AlertError label={apiError} onClose={onCloseAlertError} />
        )}
      </>
    );
  };

  const renderPatientNote = (patientNote) => {
    const renderPlainTextPatientNotes = () => {
      const noteSplitToNewlines = patientNote.notes.split('\n').map((el) => (
        <>
          {el}
          <br />
        </>
      ));

      return noteSplitToNewlines;
    };

    const renderRichTextPatientNotes = () => {
      const contentState = convertFromRaw(patientNote.notes);
      const html = convertToHTML(contentState);
      const cleanedHtml = DOMPurify.sanitize(html);
      const linkifiedHtml = linkifyHtml(cleanedHtml, { target: '_blank' });

      return (
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: linkifiedHtml,
          }}
        />
      );
    };

    const patientNotePractitioner = practitioners.find(
      (el) => el._id === patientNote.createdBy
    );

    let patientNotesPractitionerFullname = '';
    if (patientNotePractitioner) {
      patientNotesPractitionerFullname = [
        patientNotePractitioner.title,
        patientNotePractitioner.firstName,
        patientNotePractitioner.lastName,
      ].join(' ');
    } else {
      patientNotesPractitionerFullname = 'Clinic Manager';
    }

    return (
      <div className="appointment-view__patient-note appointment-view__block-content">
        <p>
          <strong>Added At:</strong>{' '}
          {dayjs(patientNote.createdAt).format('dddd Do MMMM, h:mma')}
        </p>
        <p>
          <strong>Added By:</strong> {patientNotesPractitionerFullname}
        </p>
        {typeof patientNote.notes === 'string'
          ? renderPlainTextPatientNotes()
          : renderRichTextPatientNotes()}
      </div>
    );
  };

  const renderClinicNote = (clinicNote) => {
    const noteSplitToNewlines = clinicNote.notes.split('\n').map((el) => (
      <>
        {el}
        <br />
      </>
    ));

    const clinicNotePractitioner = practitioners.find(
      (el) => el._id === clinicNote.createdBy
    );

    const clinicNotesPractitionerFullname = [
      clinicNotePractitioner.title,
      clinicNotePractitioner.firstName,
      clinicNotePractitioner.lastName,
    ].join(' ');

    return (
      <div className="appointment-view__block-content">
        <p>
          <strong>Added At:</strong>{' '}
          {dayjs(clinicNote.createdAt).format('dddd Do MMMM, h:mma')}
        </p>
        <p>
          <strong>Added By:</strong> {clinicNotesPractitionerFullname}
        </p>
        {noteSplitToNewlines}
      </div>
    );
  };

  const onClickAddNewPatientNote = () => {
    setShowNewPatientNoteForm(true);
  };

  const openGpLetter = async (appointmentId) => {
    setLoadingGpLetter(true);
    try {
      const gpLetterLink = await getLetter(appointmentId);
      window.open(gpLetterLink.url, '_blank');
    } catch (err) {
      setApiError(
        'There was an error previewing the GP letter, please refresh the page and try again.'
      );
    }
    setLoadingGpLetter(false);
  };

  const renderAppointmentNotes = () => {
    return (
      <>
        <div className="appointment-view__block">
          <div className="appointment-view__block-title">
            Patient Notes; this summary of the appointment will be shared in the
            patient’s account
          </div>
          {practitionerNotesForPatient.length > 0 ? (
            practitionerNotesForPatient.map((patientNote) =>
              renderPatientNote(patientNote)
            )
          ) : (
            <div className="appointment-view__block-content">[None]</div>
          )}
          <div className="appointment-view__add-patient-notes">
            {showNewPatientNoteForm ? (
              renderAddNewPatientNoteForm()
            ) : (
              <Button onClick={onClickAddNewPatientNote}>
                Add Patient Note
              </Button>
            )}
          </div>
        </div>

        {appointment.gpLetterRequired ? (
          <div className="appointment-view__block">
            <div className="appointment-view__block-title">
              GP letter of recommended treatment; this will be made available to
              the patient to share with their GP
            </div>
            <div className="appointment-view__block-content">
              {gpLetter.split('\n').map((el) => (
                <>
                  {el}
                  <br />
                </>
              ))}
            </div>
            {appointment.gpLetterRequired &&
              (loadingGpLetter ? (
                <LoadingSpinner />
              ) : (
                <Button
                  color="primary-outline"
                  className="appointment-view__preview-gp-letter"
                  onClick={() => openGpLetter(appointment._id)}
                >
                  Preview GP Letter
                </Button>
              ))}
            {appointment.gpLetterRequired && apiError && (
              <AlertError label={apiError} onClose={onCloseAlertError} />
            )}
          </div>
        ) : (
          <div className="appointment-view__block">
            <div className="appointment-view__block-title">
              GP letter of recommended treatment; this will be made available to
              the patient to share with their GP
            </div>
            <div className="appointment-view__block-content">
              [GP letter not required]
            </div>
          </div>
        )}

        <div className="appointment-view__block">
          <div className="appointment-view__block-title">
            Clinic GP Notes; these notes will be made available to other Health
            &amp; Her Menopause Specialist GPs
          </div>
          {practitionerNotesForClinic.length > 0 ? (
            practitionerNotesForClinic.map((clinicNote) =>
              renderClinicNote(clinicNote)
            )
          ) : (
            <div className="appointment-view__block-content">[None]</div>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className="appointment-view__banner"
        style={{ marginBottom: '2rem' }}
      >
        Appointment Status: {appointment.status}
      </div>

      {appointment.status === AppointmentStatuses.COMPLETED &&
        renderAppointmentNotes()}
    </>
  );
};

Completed.propTypes = {
  appointment: PropTypes.object.isRequired,
};

export default Completed;
