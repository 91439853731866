import { baseApiUrl, jsonPost, jsonDelete, jsonGet } from './api.js';

export const add = (data) => {
  const url = `${baseApiUrl}/schedules`;
  return jsonPost(url, data);
};

export const filterSchedules = (dateFrom, dateTo) => {
  let url = `${baseApiUrl}/schedules/filter?dateFrom=${dateFrom}`;
  if (dateTo) url += `&dateTo=${dateTo}`;
  return jsonGet(url);
};

export const hold = (scheduleId, state) => {
  const url = `${baseApiUrl}/schedules/${scheduleId}/${
    state ? 'hold' : 'release'
  }`;
  return jsonPost(url, {});
};

export const remove = (scheduleId) => {
  const url = `${baseApiUrl}/schedules/${scheduleId}`;
  return jsonDelete(url);
};
