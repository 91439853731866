import './ForgottenPassword.scss';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ForgottenPasswordEmail from './ForgottenPasswordEmail.js';
import ForgottenPasswordChange from './ForgottenPasswordChange.js';
import Content from '../../elements/Content.js';
import PageHeader from '../../elements/PageHeader.js';

const stages = {
  FORGOTTEN_PASSWORD_EMAIL: 'FORGOTTEN_PASSWORD_EMAIL',
  FORGOTTEN_PASSWORD_CHANGE: 'FORGOTTEN_PASSWORD_CHANGE',
};

const stageOrder = [
  stages.FORGOTTEN_PASSWORD_EMAIL,
  stages.FORGOTTEN_PASSWORD_CHANGE,
];

const ForgottenPassword = () => {
  const history = useHistory();
  const [currentStage, setCurrentStage] = useState(
    stages.FORGOTTEN_PASSWORD_EMAIL
  );
  const [email, setEmail] = useState();

  const onStageComplete = (data) => {
    switch (currentStage) {
      case stages.FORGOTTEN_PASSWORD_EMAIL:
        setEmail(data.email);
        break;
      case stages.FORGOTTEN_PASSWORD_CHANGE:
        history.push('/ops/O5CeoNpNYNIGC9kcCRbVnRLNDWMWunVW/login');
        break;
      default:
        throw new Error(`unexpected stage ${currentStage}`);
    }

    const currentStageIndex = stageOrder.findIndex((el) => el === currentStage);
    const nextStage = stageOrder[currentStageIndex + 1];
    if (nextStage !== undefined) {
      setCurrentStage(nextStage);
    }
  };

  const onClickStageGoBack = () => {
    const currentStageIndex = stageOrder.findIndex((el) => el === currentStage);
    const previousStage = stageOrder[currentStageIndex - 1];
    if (previousStage !== undefined) {
      setCurrentStage(previousStage);
    } else {
      history.push('/ops/O5CeoNpNYNIGC9kcCRbVnRLNDWMWunVW/login');
    }
  };

  const renderStage = () => {
    let content;
    switch (currentStage) {
      case stages.FORGOTTEN_PASSWORD_EMAIL:
        content = <ForgottenPasswordEmail onStageComplete={onStageComplete} />;
        break;
      case stages.FORGOTTEN_PASSWORD_CHANGE:
        content = (
          <ForgottenPasswordChange
            onStageComplete={onStageComplete}
            email={email}
          />
        );
        break;
      default:
        throw new Error('stage not handled');
    }

    window.scrollTo(0, 0);
    return content;
  };

  return (
    <div className="forgotten-password">
      <PageHeader showBack onClickBack={onClickStageGoBack} />

      <Content className="forgotten-password-content">{renderStage()}</Content>
    </div>
  );
};

export default ForgottenPassword;
