import { baseApiUrl, jsonPost } from './api.js';

export const activateAccount = (email, login2FactorCode, password) => {
  const url = `${baseApiUrl}/auth/activate-account`;
  return jsonPost(url, { email, login2FactorCode, password });
};

export const forgottenPasswordInit = (email) => {
  const url = `${baseApiUrl}/auth/forgotten-password/init`;
  return jsonPost(url, { email });
};

export const forgottenPasswordChange = (passwordResetCode, newPassword) => {
  const url = `${baseApiUrl}/auth/forgotten-password/change`;
  return jsonPost(url, { passwordResetCode, newPassword });
};
