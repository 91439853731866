import { baseApiUrl, jsonPatch, jsonPost, jsonGet } from './api.js';

export const patch = (appointmentId, data) => {
  const url = `${baseApiUrl}/appointments/${appointmentId}`;
  return jsonPatch(url, data);
};

export const cancel = (appointmentId, data) => {
  const url = `${baseApiUrl}/appointments/${appointmentId}/cancel`;
  return jsonPost(url, data);
};

export const sendReminder = (id) => {
  const url = `${baseApiUrl}/appointments/${id}/sendreminder`;
  return jsonPost(url, {});
};

export const refund = (appointmentId, data) => {
  const url = `${baseApiUrl}/appointments/${appointmentId}/refund`;
  return jsonPost(url, data);
};

export const getAppointment = (appointmentId) => {
  const url = `${baseApiUrl}/appointments/${appointmentId}`;
  return jsonGet(url);
};

export const move = (appointmentId, targetScheduleId) => {
  const url = `${baseApiUrl}/appointments/${appointmentId}/move/${targetScheduleId}`;
  return jsonPost(url, {});
};

export const getLetter = (appointmentId) => {
  const url = `${baseApiUrl}/appointments/${appointmentId}/gpletter`;
  return jsonGet(url);
};
