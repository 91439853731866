import './NavBar.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home.js';
import SchedulesIcon from '@material-ui/icons/DateRange';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircleIcon from '@material-ui/icons/AccountCircle.js';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Auth } from '@aws-amplify/auth';

const NavBar = (props) => {
  const { active } = props;

  const classes = ['navigation-nav-bar'];

  const onClickLogout = async () => {
    try {
      await Auth.signOut({ global: true });
      window.location = '/ops/O5CeoNpNYNIGC9kcCRbVnRLNDWMWunVW';
    } catch (err) {
      console.log('API: Error logging out');
    }
  };

  return (
    <div className={classes.join(' ')}>
      <Link
        to="/ops/O5CeoNpNYNIGC9kcCRbVnRLNDWMWunVW"
        className={`navigation-nav-bar__tab ${
          active === 'HOME' ? 'navigation-nav-bar__tab--active' : ''
        }`}
      >
        <HomeIcon className="navigation-nav-bar__icon" />
        <div className="navigation-nav-bar__label">Home</div>
      </Link>
      <Link
        to="/ops/O5CeoNpNYNIGC9kcCRbVnRLNDWMWunVW/schedules"
        className={`navigation-nav-bar__tab ${
          active === 'SCHEDULES' ? 'navigation-nav-bar__tab--active' : ''
        }`}
      >
        <SchedulesIcon className="navigation-nav-bar__icon" />
        <div className="navigation-nav-bar__label">Schedules</div>
      </Link>
      <Link
        to="/ops/O5CeoNpNYNIGC9kcCRbVnRLNDWMWunVW/search"
        className={`navigation-nav-bar__tab ${
          active === 'SEARCH' ? 'navigation-nav-bar__tab--active' : ''
        }`}
      >
        <SearchIcon className="navigation-nav-bar__icon" />
        <div className="navigation-nav-bar__label">Search</div>
      </Link>
      <Link
        to="/ops/O5CeoNpNYNIGC9kcCRbVnRLNDWMWunVW/lookup"
        className={`navigation-nav-bar__tab ${
          active === 'LOOKUP' ? 'navigation-nav-bar__tab--active' : ''
        }`}
      >
        <SearchIcon className="navigation-nav-bar__icon" />
        <div className="navigation-nav-bar__label">Lookup</div>
      </Link>
      <Link
        to="/ops/O5CeoNpNYNIGC9kcCRbVnRLNDWMWunVW/account"
        className={`navigation-nav-bar__tab ${
          active === 'ACCOUNT' ? 'navigation-nav-bar__tab--active' : ''
        }`}
      >
        <AccountCircleIcon className="navigation-nav-bar__icon" />
        <div className="navigation-nav-bar__label">Account</div>
      </Link>
      <div className="navigation-nav-bar__tab" onClick={onClickLogout}>
        <ExitToAppIcon className="navigation-nav-bar__icon" />
        <div className="navigation-nav-bar__label">Logout</div>
      </div>
    </div>
  );
};

NavBar.propTypes = {
  active: PropTypes.oneOf(['HOME', 'SCHEDULES', 'SEARCH', 'ACCOUNT', '']),
};

NavBar.defaultProps = {
  active: '',
};

export default NavBar;
