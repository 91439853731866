import './Password.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Auth } from '@aws-amplify/auth';
import Content from '../../elements/Content.js';
import PageHeader from '../../elements/PageHeader.js';
import Button from '../../elements/Button.js';
import TextInput from '../../elements/form/TextInput.js';
import FormError from '../../elements/form/FormError.js';
import TextBody from '../../elements/TextBody.js';
import FormPasswordAndConfirm from '../../forms/PasswordAndConfirm.js';
import LoadingSpinner from '../../elements/LoadingSpinner.js';

const Password = (props) => {
  const { onClickBack } = props;
  const { register, handleSubmit, watch, trigger, getValues, errors } =
    useForm();
  const [globalFormError, setGlobalFormError] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const onSubmitForm = async (data) => {
    if (requestInProgress) {
      return;
    }

    setRequestInProgress(true);

    const { currentPassword, newPassword } = data;
    setGlobalFormError(null);

    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, currentPassword, newPassword);
      })
      .then(async () => {
        await Auth.signOut({ global: true });
        window.location.href = '/ops/O5CeoNpNYNIGC9kcCRbVnRLNDWMWunVW/login';
      })
      .catch((err) => {
        setGlobalFormError(
          err.message ||
            'There was an error changing your password, please check your details and try again'
        );
        window.scrollTo(0, 0);
        setRequestInProgress(false);
      });
  };

  return (
    <>
      <PageHeader title="Change Password" showBack onClickBack={onClickBack} />
      <div className="account-password">
        <Content>
          <form onSubmit={handleSubmit(onSubmitForm)}>
            {globalFormError !== null && (
              <FormError>{globalFormError}</FormError>
            )}

            <TextBody>
              When your password is changed, you will be logged out from all
              other devices where you were logged in.
            </TextBody>

            <TextInput
              label="Current Password"
              type="password"
              name="currentPassword"
              error={errors.currentPassword}
              registerRef={register({
                required: 'This field is required',
              })}
              required
            />

            <FormPasswordAndConfirm
              errors={errors}
              register={register}
              watch={watch}
              trigger={trigger}
              getValues={getValues}
            />

            {requestInProgress === true ? (
              <LoadingSpinner />
            ) : (
              <Button type="submit">Save</Button>
            )}
          </form>
        </Content>
      </div>
    </>
  );
};

Password.propTypes = {
  onClickBack: PropTypes.func.isRequired,
};

export default Password;
