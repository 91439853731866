import './Activate.scss';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Content from '../../elements/Content.js';
import PageHeader from '../../elements/PageHeader.js';
import TextTitle from '../../elements/TextTitle.js';
import FormError from '../../elements/form/FormError.js';
import TextInput from '../../elements/form/TextInput.js';
import Button from '../../elements/Button.js';
import { activateAccount } from '../../../services/api/apiAuth.js';
import FormPasswordAndConfirm from '../../forms/PasswordAndConfirm.js';

const Activate = () => {
  const history = useHistory();
  const { register, handleSubmit, watch, trigger, getValues, errors } = useForm(
    {
      defaultValues: {
        email: 'darren@healthandher.com',
        login2FactorCode: 'testing',
        newPassword: 'Qwerty123!',
        confirmNewPassword: 'Qwerty123!',
      },
    }
  );
  const [globalFormError, setGlobalFormError] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const activateAccountRequest = async (data) => {
    setRequestInProgress(true);
    try {
      const { email, login2FactorCode, newPassword: password } = data;
      await activateAccount(email, login2FactorCode, password);

      history.push('/ops/O5CeoNpNYNIGC9kcCRbVnRLNDWMWunVW/login');
    } catch (err) {
      setGlobalFormError(
        <p>
          Incorrect activation code, please try again. If the problem continues
          please email clinic@healthandher.com
        </p>
      );
      setRequestInProgress(false);
      window.scrollTo(0, 0);
    }
  };

  const onSubmitForm = (data) => {
    if (requestInProgress) {
      return;
    }

    setGlobalFormError(null);
    activateAccountRequest(data);
  };

  return (
    <div className="activate-account">
      <PageHeader />

      <Content className="activate-account-content">
        <>
          <TextTitle>Activate your admin account</TextTitle>

          <form className="login-form" onSubmit={handleSubmit(onSubmitForm)}>
            {globalFormError !== null && (
              <FormError>{globalFormError}</FormError>
            )}

            <TextInput
              label="Email"
              type="text"
              name="email"
              error={errors.email}
              registerRef={register({
                required: 'This field is required',
                maxLength: {
                  value: 254,
                  message: 'Too many characters (max 254)',
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Must be a valid email',
                },
              })}
              required
            />

            <TextInput
              label="Code (sent in email)"
              type="text"
              name="login2FactorCode"
              autoCorrect="off"
              autoCapitalize="off"
              autocomplete="off"
              error={errors.login2FactorCode}
              registerRef={register({
                required: 'This field is required',
                maxLength: {
                  value: 10,
                  message: 'Too many characters (max 10)',
                },
              })}
            />

            <FormPasswordAndConfirm
              errors={errors}
              register={register}
              watch={watch}
              labels={['Set Password', 'Confirm Password']}
              trigger={trigger}
              getValues={getValues}
            />

            <Button type="submit">Activate</Button>
          </form>
        </>
      </Content>
    </div>
  );
};

export default Activate;
