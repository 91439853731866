import './Home.scss';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
// eslint-disable-next-line no-unused-vars
import localeEs from 'dayjs/locale/es';
import isoWeek from 'dayjs/plugin/isoWeek.js';
import PageHeader from '../elements/PageHeader.js';
import TextTitle from '../elements/TextTitle.js';
import Content from '../elements/Content.js';
import Week from './Week.js';
import { selectAdmin } from '../../slices/adminSlice.js';
import {
  getSchedulesUpdatedDate,
  getMonthsSchedules,
} from '../../slices/schedulesSlice.js';

dayjs.extend(isoWeek);

const Home = () => {
  const dispatch = useDispatch();
  const admin = useSelector(selectAdmin);
  const lastUpdated = useSelector(getSchedulesUpdatedDate);

  useEffect(() => {
    if (
      lastUpdated &&
      lastUpdated !==
        new Date().toLocaleDateString('en-GB', {
          weekday: 'long',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })
    ) {
      const getMonthSchedulesOnLoad = async () => {
        const isoDate = new Date(
          `${new Date().getMonth() + 1} 01 ${new Date().getFullYear()}`
        ).toISOString();
        await getMonthsSchedules(isoDate, dispatch);
      };
      getMonthSchedulesOnLoad();
    }
  }, [lastUpdated]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderSchedulesForCurrentWeek = () => {
    const weekStart = dayjs().startOf('isoweek');

    return <Week weekStart={weekStart} />;
  };

  const adminName = [admin.title, admin.firstName, admin.lastName].join(' ');

  return (
    <>
      <PageHeader />
      <div className="home">
        <Content fullWidth>
          <TextTitle className="home__title">Hi {adminName},</TextTitle>

          {renderSchedulesForCurrentWeek()}
        </Content>
      </div>
    </>
  );
};

export default Home;
