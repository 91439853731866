import './View.scss';
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import TextBody from '../elements/TextBody.js';
import PageHeader from '../elements/PageHeader.js';
import Button from '../elements/Button.js';
import AlertConfirm from '../elements/alert/Confirm.js';
import LoadingSpinner from '../elements/LoadingSpinner.js';
import {
  selectAllAppointments,
  replaceAppointment,
  AppointmentStatuses,
  appendAppointments,
} from '../../slices/appointmentsSlice.js';
import {
  selectAllPatients,
  appendPatients,
} from '../../slices/patientsSlice.js';
import { replaceSchedule } from '../../slices/schedulesSlice.js';
import { selectAllPractitioners } from '../../slices/practitionersSlice.js';
import AlertError from '../elements/alert/Error.js';
import Completed from './Completed.js';
import AuditLogs from './AuditLogs.js';
import Sidebar from './Sidebar.js';
import { createAuditLog } from '../../services/api/apiAuditLogs.js';
import {
  cancel,
  getAppointment,
  refund,
} from '../../services/api/apiAppointment.js';
import AlertInfo from '../elements/alert/Info.js';

dayjs.extend(advancedFormat);

const View = () => {
  const { appointmentId } = useParams();
  const appointments = useSelector(selectAllAppointments);
  const patients = useSelector(selectAllPatients);
  const practitioners = useSelector(selectAllPractitioners);
  const [loadingAppointment, setLoadingAppointment] = useState(true);
  const [appointment, setAppointment] = useState({});
  const [stripeSession, setStripeSession] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();

  async function fetchAppointment() {
    setLoadingAppointment(true);
    try {
      const appointmentData = await getAppointment(appointmentId);
      if (appointmentData.appointment)
        dispatch(appendAppointments([appointmentData.appointment]));
      if (appointmentData.patient)
        dispatch(appendPatients([appointmentData.patient]));
      setAppointment(appointments.find((el) => el._id === appointmentId));
      setStripeSession(appointment.stripeSession);
      setLoadingAppointment(false);
    } catch (e) {
      setLoadingAppointment(false);
    }
  }

  useEffect(() => {
    fetchAppointment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentId]);

  const [auditLogCreated, setAuditLogCreated] = useState(false);
  const [showAppointmentCancelConfirm, setShowAppointmentCancelConfirm] =
    useState(false);
  const [showAppointmentRefundConfirm, setShowAppointmentRefundConfirm] =
    useState(false);
  const [showAuditLogs, setShowAuditLogs] = useState(false);

  const [apiError, setApiError] = useState();

  const onCloseAlertError = () => {
    history.push('/ops/O5CeoNpNYNIGC9kcCRbVnRLNDWMWunVW');
  };

  const createAuditLogRequest = async () => {
    try {
      await createAuditLog({ appointmentId: appointment._id });
      setAuditLogCreated(true);
    } catch (err) {
      setAuditLogCreated(true);
    }
  };

  useEffect(() => {
    if (appointment && !auditLogCreated) {
      createAuditLogRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment, auditLogCreated]);

  if (!appointment && !loadingAppointment) {
    return (
      <AlertError
        label="Unable to load appointment, the appointment could not be found."
        onClose={onCloseAlertError}
      />
    );
  }

  const patient = patients.find((el) => el._id === appointment.patientId);
  if (!patient && !loadingAppointment) {
    return (
      <AlertError
        label="Unable to load appointment, the appointment could not be found."
        onClose={onCloseAlertError}
      />
    );
  }

  const onClickViewAuditLogs = () => {
    setShowAuditLogs(true);
  };

  const onClickViewAppointmentNotes = () => {
    setShowAuditLogs(false);
  };

  const onClickCancelAppointment = () => {
    setShowAppointmentCancelConfirm(true);
  };

  const onAppointmentCancelConfirmNo = () => {
    setShowAppointmentCancelConfirm(false);
  };

  const onAppointmentCancelConfirmYes = async () => {
    setShowAppointmentCancelConfirm(false);
    try {
      const details = {
        appointmentId: appointment._id,
      };
      const { appointment: cancelledAppointment, schedule } = await cancel(
        appointment._id,
        details
      );
      dispatch(replaceAppointment(cancelledAppointment));
      dispatch(replaceSchedule(schedule));
    } catch (err) {
      setApiError(
        'Unable to cancel appointment. If the problem continues please contact a developer.'
      );
    }
  };

  const onClickRefundAppointment = () => {
    setShowAppointmentRefundConfirm(true);
  };

  const onAppointmentRefundConfirmNo = () => {
    setShowAppointmentRefundConfirm(false);
  };

  const onAppointmentRefundConfirmYes = async () => {
    setShowAppointmentRefundConfirm(false);
    try {
      const details = {
        appointmentId: appointment._id,
      };
      const { appointment: refundedAppointment } = await refund(
        appointment._id,
        details
      );
      dispatch(replaceAppointment(refundedAppointment));
    } catch (err) {
      setApiError(
        'Unable to refund appointment. If the problem continues please contact a developer.'
      );
    }
  };

  const renderAppointmentDetails = () => {
    const startAt = dayjs(appointment.startAt);
    const endAt = dayjs(appointment.endAt);

    const practitioner = practitioners.find(
      (el) => el._id === appointment.practitionerId
    );

    const practitionerFullname = [
      practitioner.title,
      practitioner.firstName,
      practitioner.lastName,
    ].join(' ');

    const renderDateTime = () => {
      return (
        <div className="appointment-view-details__section">
          <TextBody emphasis>Appointment Details</TextBody>
          <TextBody>Date: {startAt.format('ddd Do MMM')}</TextBody>
          <TextBody>
            Time: {startAt.format('h:mm A')} - {endAt.format('h:mm A')}
          </TextBody>
          <TextBody>Practitioner: {practitionerFullname}</TextBody>
        </div>
      );
    };

    const renderPaymentDetails = () => {
      const { createdAt } = appointment;
      const { cancelled = {} } = appointment;
      const { refund: cancelledRefund = {} } = cancelled;

      return (
        <div className="appointment-view-details__section">
          <TextBody emphasis>Payment Status</TextBody>
          {stripeSession ? (
            <>
              <TextBody>
                Booked At: {dayjs(createdAt).format('ddd Do MMM YYYY')}
              </TextBody>
              <TextBody>
                Paid: &pound;{stripeSession.amount_total / 100}
              </TextBody>
              {cancelledRefund.refundedAmount > 0 && (
                <TextBody>
                  Refunded: &pound;{cancelledRefund.refundedAmount / 100} (
                  {dayjs(cancelledRefund.refundedAt).format('ddd Do MMM YYYY')})
                </TextBody>
              )}
            </>
          ) : (
            <TextBody>No Payment History</TextBody>
          )}
        </div>
      );
    };

    const renderActions = () => {
      const renderViewAuditLogsButton = () => {
        return <Button onClick={onClickViewAuditLogs}>View Audit Logs</Button>;
      };

      const renderViewAppointmentNotesButton = () => {
        return (
          <Button onClick={onClickViewAppointmentNotes}>
            View Appointment Notes
          </Button>
        );
      };

      const renderCancelButton = () => {
        if (appointment.status !== AppointmentStatuses.BOOKED) {
          return null;
        }

        return (
          <Button color="danger-outline" onClick={onClickCancelAppointment}>
            Cancel Appointment
          </Button>
        );
      };

      const renderRefundButton = () => {
        if (appointment.status !== AppointmentStatuses.CANCELLED) {
          return null;
        }

        const { cancelled = {} } = appointment;
        const { refund: cancelledRefund = {} } = cancelled;

        if (!stripeSession || cancelledRefund.refundedAmount > 0) {
          return null;
        }

        return (
          <Button color="danger-outline" onClick={onClickRefundAppointment}>
            Refund Appointment
          </Button>
        );
      };

      return (
        <div className="appointment-view-details__actions">
          {showAuditLogs
            ? renderViewAppointmentNotesButton()
            : renderViewAuditLogsButton()}
          {renderCancelButton()}
          {renderRefundButton()}
        </div>
      );
    };

    return (
      <div className="appointment-view-details">
        {renderDateTime()}
        {renderPaymentDetails()}
        {renderActions()}
      </div>
    );
  };

  const onCloseAlertApiInfo = () => {
    setApiError(null);
  };

  const renderMainContent = () => {
    return <Completed appointment={appointment} />;
  };

  const renderAuditLogs = () => {
    return <AuditLogs appointment={appointment} patient={patient} />;
  };

  return loadingAppointment ? (
    <>
      <PageHeader />
      <div className="appointment-view">
        <div className="appointment-view-right">
          <LoadingSpinner />
        </div>
      </div>
    </>
  ) : (
    <>
      {apiError && (
        <AlertInfo
          title="Error"
          label={apiError}
          onClose={onCloseAlertApiInfo}
        />
      )}

      <PageHeader />
      <div className="appointment-view">
        <div className="appointment-view-left">
          <Sidebar appointment={appointment} patient={patient} />
        </div>
        <div className="appointment-view-right">
          {renderAppointmentDetails()}
          <div className="appointment-view-main-content">
            {showAuditLogs ? renderAuditLogs() : renderMainContent()}
          </div>
        </div>
      </div>
      {showAppointmentCancelConfirm && (
        <AlertConfirm
          title="Cancel Appointment"
          label="Are you sure you want to cancel this appointment?"
          cancelButtonLabel="No"
          confirmButtonLabel="Yes"
          onNo={onAppointmentCancelConfirmNo}
          onYes={onAppointmentCancelConfirmYes}
        />
      )}
      {showAppointmentRefundConfirm && (
        <AlertConfirm
          title="Refund Appointment"
          label={`Are you sure you want to mark this appointment as refunded? Please ensure the payment has been refunded manually in Stripe first. Open Stripe and search for "${stripeSession.payment_intent}".`}
          cancelButtonLabel="No"
          confirmButtonLabel="Yes"
          onNo={onAppointmentRefundConfirmNo}
          onYes={onAppointmentRefundConfirmYes}
        />
      )}
    </>
  );
};

export default View;
