import './Confirm.scss';
import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import Button from '../Button.js';
import TextTitle from '../TextTitle.js';
import TextBody from '../TextBody.js';

const Confirm = (props) => {
  const { onNo, onYes, title, label, cancelButtonLabel, confirmButtonLabel } =
    props;

  return (
    <div className="alert-confirm">
      <div className="alert-confirm__content">
        <div className="alert-confirm__header">
          <TextTitle type="sub" className="alert-confirm__label">
            {title}
          </TextTitle>
          <CloseIcon className="alert-confirm__close" onClick={onNo} />
        </div>
        <TextBody>{label}</TextBody>

        <div className="alert-confirm__actions">
          <Button
            className="alert-confirm__action"
            onClick={onNo}
            color="primary-outline"
          >
            {cancelButtonLabel}
          </Button>
          <Button className="alert-confirm__action" onClick={onYes}>
            {confirmButtonLabel}
          </Button>
        </div>
      </div>
    </div>
  );
};

Confirm.propTypes = {
  onNo: PropTypes.func.isRequired,
  onYes: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string,
  confirmButtonLabel: PropTypes.string,
};

Confirm.defaultProps = {
  cancelButtonLabel: 'Cancel',
  confirmButtonLabel: 'Confirm',
};

export default Confirm;
