import { createSlice } from '@reduxjs/toolkit';
import { getAccount } from '../services/api/apiAdmin.js';
import { replaceMedicalHistories } from './medicalHistoriesSlice.js';
import { replaceMenstrualSymptoms } from './menstrualSymptomsSlice.js';
import { replaceMenopauseSymptoms } from './menopauseSymptomsSlice.js';
import { replaceMedicalProcedures } from './medicalProceduresSlice.js';
import { replaceContraceptions } from './contraceptionsSlice.js';
import { replaceHrts } from './hrtsSlice.js';
import { replaceAppointments } from './appointmentsSlice.js';
import { replacePatients } from './patientsSlice.js';
import { replacePractitioners } from './practitionersSlice.js';
import { replaceSchedules, updateLastUpdatedDate } from './schedulesSlice.js';

export const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    fetched: false,
    isAuthenticated: false,
    entity: null,
  },
  // Redux Toolkit allows us to write "mutating" logic in reducers. It
  // doesn't actually mutate the state because it uses the Immer library,
  // which detects changes to a "draft state" and produces a brand new
  // immutable state based off those changes
  reducers: {
    adminLoading(state) {
      state.fetched = false;
      state.entity = null;
    },
    adminReceived(state, action) {
      state.fetched = true;
      state.isAuthenticated = true;
      state.entity = action.payload;
    },
    adminNotAuthenticated(state) {
      state.fetched = true;
      state.isAuthenticated = false;
      state.entity = null;
    },
    replaceAdmin(state, action) {
      state.entity = action.payload;
    },
  },
});

export const {
  adminLoading,
  adminReceived,
  adminNotAuthenticated,
  replaceAdmin,
} = adminSlice.actions;

export const fetchAdmin = () => async (dispatch) => {
  dispatch(adminLoading());

  try {
    const {
      medicalHistories,
      menstrualSymptoms,
      menopauseSymptoms,
      medicalProcedures,
      contraceptions,
      hrts,
      hrtCategories,
      admin,
      schedules,
      appointments,
      patients,
      practitioners,
    } = await getAccount();
    dispatch(replaceMedicalHistories(medicalHistories));
    dispatch(replaceMenstrualSymptoms(menstrualSymptoms));
    dispatch(replaceMenopauseSymptoms(menopauseSymptoms));
    dispatch(replaceMedicalProcedures(medicalProcedures));
    dispatch(replaceContraceptions(contraceptions));
    dispatch(replaceHrts({ hrtCategories, hrts }));
    dispatch(replacePatients(patients));
    dispatch(replaceSchedules(schedules));
    dispatch(replaceAppointments(appointments));
    dispatch(replacePractitioners(practitioners));
    dispatch(adminReceived(admin));
    dispatch(updateLastUpdatedDate());
  } catch (err) {
    dispatch(adminNotAuthenticated());
  }
};

// Selectors ******************************************************************
// The functions below are called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export const selectAdmin = (state) => {
  return state.admin.entity;
};

export const selectAdminFetched = (state) => {
  return state.admin.fetched;
};

export const selectAdminIsAuthenticated = (state) => {
  return state.admin.fetched && state.admin.isAuthenticated;
};

export default adminSlice.reducer;
